body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input,
select,
.select div div div, #classe-anno {
  background: #fffc !important;
}
.block {
  display: block;
}
.inline,
.inline label {
  display: inline-block;
}
.container {
  max-width: 1280px;
  margin: 0 auto;
}
.v-divider-top {
  margin-top: 80px;
}
.v-divider {
  margin: 30px 0!important;
  justify-content: center;
}
.box-well, .step-4 .booking-box {
  background: #ffc41a;
  border: solid 3px #0173d4;
  /*border-radius: 9px;
  text-align: left;*/
  padding: 3% !important;
}
.lateral-margin-8px {
  margin-left: 8px !important;
}
.lateral-margin-16px {
  margin-left: 16px !important;
  margin-right: 16px !important;
  padding: 0px !important;
}
.align-self-end {
  align-self: flex-end;
}
.step-4 .box-well, .step-4 .booking-box{
  background: #cce1ff;
}
.box-form {
  text-align: left;
  padding: 3% !important;
}
.box-dark {
  background: #333;
  color: #fff;
  margin: 15px !important;
  padding: 3%;
}
.box-dark label span {
  color: #fff;
}
.box-dark p {
  margin: 0;
}
.color-gs {
  background-color: #1d3044 !important;
}
.color-lme{
  background-color: #cd257e!important;
}
.step-nav svg circle {
  font-size: 3rem;
}
.footer-step-nav {
  text-align: center;
  margin: 30px 0;
}
.alert {
  padding: 3%;
  border-radius: 5px;
  box-shadow: 0 0 2px #ccc;
  color: #555 !important;
  font-weight: 500;
  width: 92%;
  margin: 20px auto;
}
.alert-primary,
.alert.alert-primary {
  /*background: #fdfdd4 !important;*/
  background: #d3d4cc;
  box-shadow: none !important;
}
.alert-primary label,
.alert.alert-primary label {
  /*display: inline-block;*/
}
.btn-action {
  background: #8dc636 !important;
}
.select-draft {
  width: 80%;
  padding: 5px 10px;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.87);
  background: rgba(255,255,255,0.8)!important;
}
.label-draft {
  margin-bottom: 15px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.54) !important
}
.StepThreeForm-textField8-491 label {
  color: rgba(0, 0, 0, 0.87);
}





/* STYLE FORM */

.form-container {
  background: #F3F6F9;
  margin-top: 0 !important;
  border-radius: 20px;
}
.form-sidebar {
  background: #0172e4;
  background: linear-gradient(to right, #0172e4, #0059B3);
}
.sidebar-price-recap{
  display:flex;
  flex-direction:column-reverse;
}

@media screen and (min-width: 901px) {
  .fixed {
    display:block;
    background: #0172e4;
    background: linear-gradient(to right, #0172e4, #0059B3);
    position: fixed;
    width:326px;
    padding:12px;
    right: 0;
    top: 100px;
    height:100%;
    box-sizing:border-box;
    }

  .sidebar-price-recap{
  flex-direction:column;
}
}

@media screen and (min-width: 1304px) {
  .fixed {
    right: calc((100% - 1304px)/2);
    }
}

.participant-form-container{
  padding:0 16px 20px;
  margin-bottom: 20px;
}
.participant-form-container .MuiGrid-item {
    padding-top:0 !important;
    padding-bottom:0 !important;
}

.step-nav-wrapper {
  padding: 0;
}
/*.App .select {
  margin-top: 0;
}*/
.App .box-well {
  margin-bottom: 50px;
}
.App form .MuiGrid-item-112:first-child h6 {
  margin-top: 0;
}
.App form h6 {
  text-transform: uppercase;
  font-weight: bold;
  color: #014174;
  padding-left: 0;
  border-bottom: 1px solid;
  width: 97.5%;
  margin: 0 1%;
  /*margin-top: 25px;*/
}
.App form .btn-info, .btn-info {
  height: 20px;
  background: #27aae1;
  padding: 13px 10px !important;
  font-weight: bold;
  margin-top: 15px;
  color: #fff;
  border: 2px solid;
  line-height: 0em;
}
.App form .btn-info:hover, .btn-info:hover {
  background: #3f51b5;
}
/*
.App form .MuiRadio-colorSecondary-349,
.App form .MuiRadio-colorSecondary-421,
.App form .MuiRadio-colorSecondary-600,
.App form .alert .MuiRadio-colorSecondary-679 {
  padding: 0px;
  margin-left: 10px;
}*/
.App .alert fieldset legend,
.App .box-well fieldset legend {
  margin-bottom: 10px;
}
.App .label + .MuiInput-formControl-292 {
  margin-top: 25px;
}
.App .MuiFormControl-marginNormal-263 {
  margin-top: 0;
}
.App .boxed {
  width: 97%;
  margin: 0 1%;
  background: #f7f7f7;
  border: 1px solid #e9f0fa;
  padding: 10px;
}
.App .boxed h3 {
  padding-left: 7px;
}
.App .boxed img {
  width: 100px;
  height: auto;
  display: block;
}
/* ASIDE */
.MuiGrid-spacing-xs-24-135 > .MuiGrid-item-112 {
  padding-top: 0;
}
.aside-panel {
  /*border: 1px solid #cce1ff;*/
  border-radius: 3px;
  margin-bottom: 20px;
  width: 99%;
}
.aside-panel-deposit {
  border-radius: 3px;
  margin-bottom: 20px;
  width: 99%;
}
.aside-panel-deposit-mobile {
  border-radius: 3px;
  margin-bottom: 16px;
  display: none;
  margin-top:16px;
}
.aside-title {
  background-color: #e9f0fa7a;
  border-bottom: 3px solid #cce1ff;
}
.aside-title-deposit {
  background-color: #3d8adb;
  border:3px solid  #ffc41d;
  padding-top:0px !important;
  padding-bottom:0px !important;
  border-radius: 10px;
}
.aside-title span {
  font-size: 16px;
  font-weight: bold;
}
.aside-content ul {
  padding-left: 15px;
}
.aside-content ul li {
  font-size: 15px;
}
.highlight-color {
  background: #ffc41a !important;
}
.aside-panel ul li {
  color: #fff;
  list-style-type: disc;
  line-height: 1.4;
  font-weight: 500;
  letter-spacing: 0;
}

/* form elements */
/*
.Select-control {
  display: flex;
  text-align: center;
  border: 0;
  height: auto;
  background: transparent;
}
.Select-control:hover {
  box-shadow: none;
}
.Select-multi-value-wrapper {
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}
.Select--multi .Select-input {
  margin: 0;
}
.Select.has-value.is-clearable.Select--single > .Select-control .Select-value {
  padding: 0;
}
.Select-noresults {
  padding: 30px;
}
.Select-input {
  display: inline-flex !important;
  padding: 0;
  height: auto;
  position: relative;
}
.Select-input input {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: 0;
}
.Select-input div {
  position: absolute !important;
  z-index: 10;
  background: #fff;
}
.Select-placeholder,
.Select--single .Select-value {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  text-align: center;
  padding: 0;
}
.Select-placeholder {
  opacity: 0.42;
}
.Select-menu-outer {
  position: absolute;
  left: 0;
  top: calc(100% +10px);
  width: 100%;
  z-index: 2;
}
.Select.is-focused:not(.is-open) > .Select-control {
  box-shadow: none;
}
.Select-menu {
  overflow-y: auto;
}
.Select-menu div {
  box-sizing: content-box;
}
.Select-arrow-zone,
.Select-clear-zone {
  cursor: pointer;
  height: 21;
  width: 21;
  z-index: 1;
}
.Select-clear-zone {
  visibility: hidden;
}
.Select-aria-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1;
  width: 1;
  margin: -1;
}
*/
.select-block {
  margin-top: 5px !important;
}
.select-block .Select-control {
  padding: 10px;
}
.select-block .Select-input {
  width: 98%;
}
.select-block input {
  width: 98% !important;
  font-size: 1.1em;
  padding: 5px !important;
  border-color: rgba(0, 0, 0, 0.42);
  border-style: solid;
  border-width: 0 0 1px;
}
div#react-select-2--list,
.Select-menu-outer div.Select-menu {
  position: relative;
}
.Select-noresults {
  position: absolute;
  width: 98%;
  max-width: 250px;
  background: #fff;
  top: -10px;
  left: 10px;
  padding: 5px;
  box-shadow: 0 1px 3px #bbb;
  color: #f44336;
}
.select-block .Select.has-value.Select--single,
.jss237,
.MuiFormControl-marginNormal-237 {
  margin-top: 4px !important;
}
.select-block .Select {
  position: relative;
}
.Select-menu-outer {
  position: absolute;
  z-index: 2;
  background: #fff;
  top: 40px;
  left: 10px;
  width: 98%;
  max-width: 250px;
  box-shadow: 0 1px 3px #bbb;
}
.Select-menu-outer {
  overflow-y: scroll;
  max-height: 400px;
}
.Select-menu-outer .Select-option {
  line-height: 2;
  padding: 0 14px;
}
.Select-value .Select-value-label {
  position: absolute;
  font-size: 1.3em;
  padding: 3px 5px;
}
legend {
  line-height: 1.2 !important;
}
span.Select-clear-zone {
  display: none;
}
.inline-block {
  flex-direction: row !important;
}
.inline-block * {
  display: inline-block !important;
}
/* Extra services
*/
.pricing-table {
  text-align: center;
  padding: 3%;
  background: #fff;
  border-radius: 5px;
  border-top: solid 5px #236fbf;
  box-shadow: 0 0px 40px #ccc;
  margin: 50px 0;
}
/* Each pricing-item*/
.pricing-item {
  height: auto;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  background: #fff;
  border: solid 1px #cecece;
}
.pricing-item:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.23), 0 3px 12px rgba(0, 0, 0, 0.16);
}

/* pricing-value */
.pricing-value {
  width: 100%;
  /* height: 180px; */
  padding: 10px 0;
  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;
  margin: 10px auto;
  line-height: 1.3;
  transition: .2s;
}
.pricing-value .smallText {
  font-size: 18px;
}
.pricing-value .undertext {
  display: block;
  padding: 4%;
  font-size: 22px;
}
.pricing-value {
  /*border: 1px solid #0d47a1;*/
  color: #0d47a1;
  background: #efefef;
}
.pricing-item .button {
  margin: 10px auto;
  margin-bottom: 10px;
  background: #f38d04;
  padding: 10px;
  font-size: 1.3rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  display: inline-block;
}
.pricing-item.selected {
  border-color: #ffc41a;
  border-width: 8px;
  -webkit-transform: scale(1.06);
  -ms-transform: scale(1.06);
  transform: scale(1.06);
  z-index: 1;
}
.pricing-item.selected .pricing-value {
  background: #ffc41a;
}
.pricing-item.selected .button {
  background: #01a34a;
}
.radio-inline {
  margin: 20px 0 0 20px!important;
}
.box-well .booking-box p {
  font-size: 0.9rem;
  color: rgba(0,0,0,0.8);
}
.bordered-bottom {
  border-bottom: solid #0003;
}
/* sidebar wizard */
.aside-content {
  display: block !important;
  margin: 0 !important;
  padding: 0 !important;
}
.aside-panel .aside-content ul {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0 !important;
}
.aside-panel .aside-content ul li {
  list-style: none;
  padding: 3px;
}
.item-no-style {
  list-style: none !important;
}
/* .pak-optioned:before {
  content: "Pacchetto selezionato";
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: solid 1px;
  color: #222;
  padding: 5px;
} */
.pak-optioned {
  background: #f3f6f9;
  border: 5px solid #ffc41a;
  border-radius: 0 0 10px 10px;
}
.pak-optioned li {
  padding: 5px 10px;
}
.aside-content .pak-optioned * {
  color: #012089;
  font-weight: 600;
}
.aside-content .pak-optioned li,
.aside-content .pak-preventivo li {
  list-style: none;
}
/* .pak-preventivo:before {
  content: "Pacchetto seconda scelta";
  content:"";
  font-size: 1.1rem;
  font-weight: 600;
  border-bottom: solid 1px;
} */
li.pak-preventivo {
  padding: 15px;
}
.content-summary {
  background: #fff;
  padding: 3%;
}
.content-summary p, .content-summary dd, .content-summary dt, .content-summary li {
  color: #444!important;
}
.content-summary dd, .content-summary dt {
  width: 95%!important;
  text-align: left;
  float: left;
  padding: 5px 10px!important;
  margin: 0;
}
.content-summary dd {
  border-bottom: dotted 1px #c9c9c9;
}
.content-summary .aside-booking {
  width: 70%;
  margin: 15px auto;
}
.content-summary li.big-text.pk-price {
  background: #236fbf;
  color: #ffeb66!important;
}
li.big-text.pk-price {
  color: #ffc41d;
  font-size: 1.35rem;
  font-weight: 700;
  list-style: none;
  padding: 2%;
  border: solid;
  clear: both;
  margin-top: 15px;
  display: inline-block;
  width: 95%;
  border-radius: 10px;
}
li.big-text.big-text2.pk-price {
  font-size: 1.2rem;
  color: #f4f6f9;
}
li.big-text.pk-price span{
  float: right;
  text-align: right;
}
.box-pp a {
  font-weight: 700;
}
.box-pp > *, .box-pp a {
  font-size: 0.8rem;
}
.box-pp label span {
  font-size: 0.85rem;
}
.footer-step-nav {
  border-top: solid 1px #ccc;
  padding-top: 30px;
}
label{
  color: rgba(0, 0, 0, 1)!important;
  padding-left: 5px!important;
}
input, select, .select div div div, #classe-anno{
  background: #ffffff7a !important;
  border: solid 2px #fff!important;
}
#inps-contributo {
  margin-top: 16px;
}
.box-light {
  background: rgba(255,255,255,0.6);
  padding: 3%;
  border-radius: 5px;
  margin: 15px 0;
}
span.text-warning {
  display: block;
  background: #ffadad;
  padding: 1%;
  border: solid #ff0000;
}
#menu- li{
  white-space: inherit!important;
  border-bottom: dotted 1px #cdcdcd;
  min-height: 28px;
  line-height: 1.2;
}

/* STEP 4 */

.pricing-item {
  display: grid;
  grid-template-rows: 1fr auto;
}
.pricing-item .pricing-title {
  position: relative;
  color: white;
  background: #0d47a1;
  font-weight: 400;
  font-size: 19px;
  padding: 20px 10px;
  text-transform: uppercase;
}
.help-text {
  font-size: .8rem;
}
.item-extra-cta {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.item-extra-container {
  display: grid;
  grid-template-rows: 6fr auto 7fr;
}

@media screen and (max-width: 823px){
  .container-fluid{
    max-width: 98%;
    margin: 0 auto;
  }
  .box-form.form-content{
    max-width: 95%;
  }
  .col-xs-12{
    width: 97%!important;
  }
  #menu- li{
    white-space: inherit!important;
    border-bottom: dotted 1px #cdcdcd;
    /*height: 32px;*/
  }
  .item-extra-container .pricing-title, .item-extra-container .pricing-value {
      font-size: 1rem;
      min-height: inherit!important;
  }
  .pricing-item {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-gap: 5px;
  }
  .pricing-item .button {
    box-sizing: border-box;
    font-size: .8rem;
  }
  .item-extra-cta .help-text {
    font-size: .9rem;
  }
  img{
    max-width: 100%;
  }
  .item-extra-cta {
    border: 0;
  }
  .box-form.form-content blockquote{
    max-width: 95%;
    margin: 5px;
  }
  .box-form.form-content blockquote ul, .box-form.form-content blockquote ol {
    margin: 0 0.5rem;
    padding: 0 0.5rem;
  }
}
@media screen and (max-width: 736px) {
  /* Styles */
  .step-nav-wrapper {
    display: none !important;
  }
  .form-content {
    padding: 3% !important;
    margin: 0 10px 0 25px !important;
  }
}
/* Date input */
.DayPickerInput{
  margin-top: 18px;
}
.DayPickerInput input {
  padding: 6px;
  font-size: 1rem;
  margin-top: 14px;
  margin-left: 7px;
}
.no-margin{
  margin-top: 0!important;
}
.Select-placeholder {
  color: #787878;}
/* tag mng in sidebar */

.tag_mng li{
  visibility: hidden;
  height: 1px;
}
.tag_mng li.active-step{
  display: block;
  color: #236fbf;
}
#tag_mng_step5{
  color: #fff!important;
}
.btn-info, .btn-cf, .btn-info.btn-cf {
  height: 20px;
  background: #27aae1;
  padding: 13px 10px !important;
  font-weight: bold;
  margin-top: 15px;
  color: #fff;
  border: 2px solid;
  line-height: 0em;
}
.btn-info, .btn-cf[disabled], .btn-info.btn-cf[disabled] {
  background: #ccc;
}
.btn-info:hover, .btn-cf:hover, .btn-info.btn-cf:hover {
  background: #3f51b5;
}
.progress-size {
  float: right;
  margin: 0 10px !important;
  height: 38px !important;
  width: 38px !important;
}

small.text-small.text-danger {
  display: inline-block;
  font-size: 0.85rem;
  font-weight: normal;
  background: #ffffffb5;
  color: #a90000;
  padding: 3%;
}
.price-specs {
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content;
  align-items: center;
  grid-gap: 10px;
}
.price-specs small {
  grid-column: 1/ span 2;
}

.price-si dt{
  width: 70%;
}
.price-si dt, .price-si dd{
  display: block;
  float: left;
  color: #fff;
  line-height: 1.47;
  font-size: 0.9rem;
}
.price-si dd{
  float: right;
  font-weight: bold;
  text-align: right;
  padding: 0 5px;
}
.price-si dd:after{
  content:"";
  width: 100%;
  display: block;
  clear: both;
}
.price-si .text-highlights{
  background: #ffc41a;
  color: #111;
  padding: 2px;
  border-radius: 5px;
}
.price-si dd.dl-full, .price-si dt.dl-full {
    float: none;
    clear: both;
    margin: 0px!important;
}
.margin-top-16{
  margin-top: 16px!important;
}
.mdl-list.list {
  padding: 10px 5px !important;
}
.text-center, .content-summary .text-center{
  text-align: center!important;
}
.help-info{
  font-size: 0.65rem;
  font-weight: 600;
  margin: 7px 5px;
  border: solid 2px rgba(255,255,255,0.5);
  padding: 2px 2px;
}
.help-info a{
  color: #ffc41a;
  text-transform: uppercase;
  text-decoration: none;
}
.align-left{
  text-align: left;
}
.bonus-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px;
  margin-bottom: 80px !important;
}
.bonus-wrapper h6 {
  display: inline-flex;
  font-size: 30px;
  margin-top: 20px;
}

/* Mobile bottom bar styling */

.expand-icon {
  display: none !important;
}
@media screen and (max-width: 900px) {
  .form-content {
    padding-bottom: 75px !important;
    margin: 0 auto !important;
  }
  .aside-panel, .price-specs, .aside-panel:first-child .aside-title  {
    display: none;
  }
  .aside-panel-deposit {
    display: none;
  }
  .aside-panel-deposit-mobile {
    display: flex;
  }
  .mobile-block-show {
    display: block !important;
  }
  .mobile-grid-show {
    display: grid !important;
  }
  .form-sidebar {
    right: 0;
    height: 90px;
    padding: 0 10px !important;
    overflow-y: auto;
    max-height: 300px;
    transition: .7s;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    max-width: 100% !important;
    z-index: 100;
  }
  .auto-height{
    height: 280px !important;
  }
  .price-si {
    margin: 0 10px;
    display: flex;
    margin: 0 !important;
    flex-direction: column-reverse;
  }
  li.big-text.pk-price {
    width: 80%;
    padding: 1%;
    font-size: 1.6rem;
  }
  .expand-icon {
    position: fixed !important;
    right: 30px;
    margin: 5px;
    z-index: 999;
    bottom: 0;
    width: 25%;
    display: inline-flex !important;
    transition: cubic-bezier(0.075, 0.82, 0.165, 1), .7s;
    align-self: center;
    font-size: 30px;
    border: 2px solid #d09c0a;
  }
  .expand-icon-rotated {
    transform: rotate(180deg)
  }
  .aside-panel:first-child {
    display: block;
    margin: 5px 0;
  }
  .aside-list {
    padding: 0 !important;
  }
  .pricetag-wrapper {
    display: inline-flex;
    padding: 0 7px;
  }
  .pricetag {
    margin-top: 0px !important;
  }
  .tag_mng {
    display: none;
  }
  .form-sidebar {
    height: 60px;
  }
  .expand-icon {
  right: 0px;
  }
  .pricing-table {
    border: solid 5px #236fbf;
  }

  /* Step 2 */

  .bonus-wrapper {
    display: inline-block;
    padding: 0 !important;
    margin-left: 20px!important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
  }
  .App .boxed img {
    width: 60px;
    height: 50px;
    display: inline-flex;
    margin-left: 20px;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .pricing-table {
    border: solid 5px #236fbf;
  }
  .aside-title {
    padding: 10px !important;
  }
  .form-content {
    flex-basis: 70%;
    max-width: 70%;
  }
  .form-sidebar {
    flex-basis: 30%;
    max-width: 30%;
  }
  .suggest-item .suggest-label small {
    margin: 0;
  }
}
.textlight, h3.textlight{
  font-weight: 500;
}
.extra-closure-text {
  display: block;
  width: 100%;
  margin-top: 20px;
}
.extra-closure-text .btn {
  padding: 10px 15px;
  margin: 2px;
  background: #eee;
  border-radius: 3px;
  border: solid 2px transparent;
  box-shadow: 0 0px 10px #ddd;
}
.extra-closure-text .btn-primary {
  color: #fff;
  background-color: #f50057!important;
  text-transform: uppercase;
}
.extra-closure-text .btn-primary:hover{
  background-color: #c30447!important;
}
@media screen and (max-width: 600px) {
  .form-sidebar {
    height: 55px;
  }
  header .help-info{
    font-size: 0.45rem!important;
  }
  header .avatar-user{
    margin: 0!important;
  }
}
/* Suggest extra-services */
.suggest-item {
  border: solid 5px #0acd90;
  border-radius: 5px;
  transform: scale(1.05);
}
.suggest-item .suggest-label small {
  display: block;
  background: #0acd90;
  margin: 0 -9px;
  font-weight: bold;
  padding: 2px!important;
}

/* braintree */

[data-braintree-id="toggle"] {
  /*display: none;*/
}

.cf-field .MuiFormHelperText-root{
  color:#8f1408;
  background-color: rgb(255, 250, 229);
  padding: 1%;
}
.cf-field input:not([value=""]){
  background: rgb(255, 250, 229)!important;
}
div[align-items=center]{
  text-align: center;
}
h3,h4,h5,h6{
  margin: 5px 0 10px!important;
}
.boxlighter {
  background: rgb(255, 250, 229);
  padding: 2%!important
}
.itemHidden{
  display: none;
}
input[type=date]{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.32)!important;
}
html body .MuiFormControl-marginNormal{
  margin-top: 9px!important;
}

@media screen and (min-width: 768px) {
  .paystep .aside-panel:last-child{display: none;}
  .paystep .aside-panel-deposit-mobile{
    display: block;
    margin-right:45px;
  }
  .paystep .aside-panel-deposit{
    display: none;
  }
  .sidebar-price-recap{
  flex-direction:column-reverse;
}
  .paystep .content-summary .aside-booking{
    width: 94%;
  }
  .paystep, .paystep .aside-booking {
    margin-top: 0!important;
    padding-top: 0!important;
  }
  .paystep .aside-booking dl.price-si {
    margin-top: 0;
    padding: 0;
  }
  .paystep .aside-booking ul, .paystep .aside-booking li, .paystep .aside-booking .price-si dt, .paystep .aside-booking .price-si dd {
    padding: 5px!important;
    margin: 0;
    line-height: 1.2;
    font-size: 1.1rem;
  }
  .paystep .content-summary{
    padding: 0;
  }
  .paystep ul.aside-list .aside-panel .aside-title{
    display: none;
  }
  .paystep .aside-list{
    display: flex;
    flex-direction: column;
  }
  .paystep ul.aside-list .aside-panel:nth-child(2) {
    order: 1;
    margin: 0;
  }
  .paystep ul.aside-list .aside-panel:nth-child(1) {
    order: 2;
    margin: 0;
  }
  .paystep ul.aside-list .aside-panel:nth-child(3), .paystep .container-msg {
    display: none;
    margin: 0;
  }
  .paystep .small.text-small.text-danger{
    padding: 1px!important;
  }
  .paystep .price-specs {
    margin: 0;
    grid-gap: 2px;
  }
  .paystep .price-spaces dd, .paystep .price-spaces dl{
    font-size: 0.85rem;
  }
  .paystep small.text-small.text-danger{
    padding: 5px 0;
  }
  .paystep .pricetag-wrapper .pk-price{
    font-size: 1.2rem;
  }
  .big-text .MuiAlert-message, .big-text .MuiAlert-message .MuiAlertTitle-root {
    font-size: 1.3rem;
  }
  .paystep .content-summary li.big-text.pk-price {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 767px) {
  .paystep .aside-panel-deposit-mobile{
    display: none;
  }
  .paystep .aside-panel-deposit{
    display: block;
    margin-top:8px;
  }
}
.color-ada, .form-sidebar.div-3{
  background: #30abae;
}
.form-sidebar.div-3 .tag_mng li.active-step{
  color: #379465;
}
.extra-accordion .MuiExpansionPanelSummary-root{
  margin:0!important;
  padding: 15px 5px 15px 5px!important;
}
.extra-accordion .MuiExpansionPanelSummary-root label{
  margin: 2px!important;
  padding: 2px!important;
}
.extra-accordion .MuiExpansionPanelSummary-root label span{
  margin:0;
  padding:2px;
}
.extra-accordion .MuiExpansionPanelSummary-content {
  margin: 2px 0;
}
.extra-accordion .MuiExpansionPanelSummary-content p:after {
  content: " ⬇";
  display: inline;
  padding-left: 5px;
  color: #ffc41a;
}
.aside-list{
  overflow-y: scroll!important;
  max-height: 90vh!important;
}

.voucher-box{
  background: #fff8cc!important;
  border: solid 5px #ffc41a!important;
  margin: 3rem 0!important;
  padding: 3%!important;
  max-width: 93%!important;
}
button.v-margin-top{
  margin: 1.4rem 0.2rem!important;
}
.voucher-message {
  background: #e9f0fa;
  padding: 1% 0;
  font-weight: bold;
  font-style: italic;
  display: inline-block;
}

.modal .popup-content {
  max-height: 100vh !important;
  overflow-y: scroll !important;
  min-height: 100px;
  min-width: 500px;
}

a.btn.btn-default a.btn-club {
  border: solid 1px #cecece;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 0.8rem;
}
a.btn-club{
  background: none;
  color: rgba(0,0,0,0.65)!important;
  text-decoration: none;
}
a.btn-club:hover{
  background: rgba(0,0,0,0.035) !important;
}
.big-text .MuiAlert-standardWarning {
  color: #555!important;
  background-color: rgb(252, 245, 189);
  border: solid 1px #ffc41a;
}
/* Summary style */
.title-summary{
  font-size: 1.42rem;
}
.content-summary li.big-text.pk-price{
  background: #0172e4;
  background: linear-gradient(to right, #0172e4, #0059B3);
  font-size: 1.5rem!important;
  margin: 15px 0;
}


/*Count down css*/

.show-counter {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1.75rem;
  text-decoration: none;
  border-radius: 0.25rem;
}
.show-counter .counter-times {
  color: #000;
  margin-left: 10px;
  background-color: #ececec;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1.75rem;
  text-decoration: none;
  border-radius: 0.25rem;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
  font-weight: bold;
  font-size: 1.3rem;
}

.show-counter .countdown > small {
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: normal;
}

/* Servizi Opzionali */

.wrap-extra-opt {
  padding: 3%;
  background: #fff;
  border-radius: 5px;
  /*box-shadow: 0 0px 40px #ccc;*/
  margin: 10px 0;
  border: 0.7rem solid hsl(200 100% 60%);
    border-image-source: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-image-slice: 1;
    animation: animatedgradient 3s ease alternate infinite;
}
.wrap-extra-opt h5 {
  padding: 0;
}
.extra-item-opt {
  margin: 8px 0;
}
.extra-item-opt .v-divider {
  box-shadow: none!important;
  padding: 0;
}
.extra-item-opt .MuiExpansionPanelSummary-expandIcon span.MuiIconButton-label:before {
  content: "Scopri ed aggiungi";
  font-size: 1rem;
}
/* :not(extra-accordion) */
.extra-item-opt .extra-accordion .MuiExpansionPanelSummary-expandIcon span.MuiIconButton-label:before{
  content: "";
}

.extra-item-opt .MuiExpansionPanelSummary-expandIcon.Mui-expanded span.MuiIconButton-label:before {
  transform: rotate(0deg);
  content:"";
}
.extra-item-opt-title, p.extra-item-opt-title {
  font-weight: 500!important;
  padding: 10px;
}


@keyframes blink {
  0%, 50% { opacity: 1; }
  100% { opacity: 0; }
}


@keyframes animatedgradient {
	0% {
    border-image-source: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	}
	50% {
    border-image-source: linear-gradient(180deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	}
	100% {
    border-image-source: linear-gradient(360deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
	}
}

.countdown-blinking {
  animation: blink 3s linear infinite;
}

@media only screen and (max-width: 768px) {
  #countdown-timer .show-counter{
    flex-direction: column;
    align-items: flex-start;
  }
  #countdown-timer .counter-times{
    background: transparent;
    line-height: 1.1;
  }
  #countdown-timer h6{
    font-size: 1rem!important;
  }
}
@media only screen and (max-width: 500px) {
  .show-counter .countdown > span {
    font-size: 1rem;
    line-height: 1rem;
  }

  .show-counter .countdown-link {
    font-size: 1.5rem;
  }
}

/*Larghezza box extra-services al massimo*/
.extra-list {
  width: 100%;
}
.box-light.boxed .light-box{
  margin-top: 2rem;
  border-bottom: 1px solid #ccc;
}

apple-pay-button {
    --apple-pay-button-height: 55px;
    --apple-pay-button-min-height: 40px;
    --apple-pay-button-max-height: 55px;
    --apple-pay-button-width: 100%;
    --apple-pay-button-min-width: 500px;
    --apple-pay-button-max-width: 750px;
    --apple-pay-button-border-radius: 28px;
    --apple-pay-button-margin-bottom: 17px;
    margin-bottom: 17px;
}

.dayEnabled {
    border: solid 3px orange !important;
    width: 50px;
    height: 50px;
    border-radius: 10%;
    background: none !important;
}

.daySelected {
    background: rgb(134 211 172 / 60%) !important;
}

.dayEnabled.daySelected {
    border-color: green !important;
    color: #222;
    font-weight: bold;
}

.react-calendar {
    width: 100% !important;
}

button.react-calendar__navigation__arrow.react-calendar__navigation__next2-button,
button.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button {
    display: none !important;
}

.react-calendar__month-view__days__day {
    width: 50px;
    height: 50px;
}

.react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
}
