.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  /* background-color: #282c34; */
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-footer a {
  margin: 0 10px;
  /* color: #FFFFFF; */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.extra-list .v-divider div:nth-child(2){
  display: flex;
  flex-direction: column;
}
.extra-list .v-divider #extra-service-840{
  order: 1;
}
.extra-list .v-divider #extra-service-841{
  order: 2;
}
.extra-list .v-divider #extra-service-962{
  order: 2;
}
.extra-list .v-divider #extra-service-9033{
  order: 3;
}
.extra-list .v-divider #extra-service-9193{
  order: 4;
}
.extra-list .v-divider #extra-service-842{
  order: 5;
}
.extra-list .v-divider #extra-service-907{
  order: 1;
}
.extra-list .v-divider #extra-service-908{
  order: 2;
}
.extra-list .v-divider #extra-service-937{
  order: 2;
}
.extra-list .v-divider #extra-service-9017{
  order: 3;
}
.extra-list .v-divider #extra-service-9189{
  order: 4;
}
.extra-list .v-divider #extra-service-909{
  order: 5;
}
.extra-list .v-divider div[id^="extra-service-"]{
    order:10;
}
